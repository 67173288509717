.radio-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f8f6f1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.radio-container-inner {
  padding: 10px;
  width: 100vw;
  margin-top: 120px;
}

.radio-header-container {
  margin-top: 0;
  width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.radio-station-item-small {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.radio-header-small-container {
  width: 75vw;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.radio-header-small-inner-container {
  flex-direction: row;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 5px;
}

.radio-playlist-text {
  text-align: left;
  font-size: 14px;
}

.radio-type-text {
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 2rem;
  font-weight: bold;
}

.radio-songs-text {
  text-align: left;
}

.control-bar-title-text {
  font-weight: "bold";
  font-size: 18px;
}

.control-bar-genre-text {
  font-weight: 300;
  font-size: 14px;
  padding-top: 4px;
}

.control-bar-flex {
  display: flex;
}

.control-bar-center-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.control-bar-wave-inner {
  width: 100px;
  height: 150px;
}

.control-bar-wave-ref {
  margin-top: 50px;
  height: 0;
  width: 0;
  visibility: hidden;
}

.control-bar-countdown-container {
  background-color: #70322a;
  color: white;
  height: 50px;
  width: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  transition: 0.5s;
  margin-left: 20px;
}

@media (max-width: 500px) {
  .control-bar-countdown-container {
    width: 80px;
    margin-left: 0;
  }
}
