.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

* {
  /* box-sizing: border-box; */
  margin: 0;
  padding: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family: "Veneer";
  src: url("./assets/fonts/Veneer.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.Video-title {
  font-family: "Veneer", sans-serif;
  color: black;
  text-align: left;
  margin-bottom: 0;
  margin-top: 0px;
  padding-right: 40px;
  font-weight: 500;
  font-size: 36px;
  transition: height 1s;
}

@font-face {
  font-family: "Bitter";
  src: url("./assets/fonts/Bitter-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Apply the font globally or to specific elements */
body {
  font-family: "Bitter", sans-serif;
}

.App-header {
  background-color: #832216;
  /* min-height: 100vh; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.splash-iframe {
  width: 100%;
  height: 100%;
}

.screen-container {
  width: 100%;
  height: 100%;
  background-color: #f8f6f1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.splash-container {
  background-color: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.App-link {
  color: #61dafb;
}

.playback-area {
  flex: 2;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.playback-container {
  width: 70%;
}

.track-section {
  display: flex;
  gap: 20px;
  padding: 20px;
  font-family: "Bitter", sans-serif;
  background-color: #fefdfb; /* Subtle background color */
}

.track-list {
  width: 40%;
  background: #f8f6f1;
  border-radius: 8px;
  padding: 15px;
  padding-left: 0px;
  padding-right: 40px;
  z-index: 1;
  width: 100%;
  min-height: 90px;
  margin-left: 5px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}

.track-list h3 {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.track-item {
  width: 90%;
  min-height: 40px;
  padding: 10px 15px;
  z-index: 1;
  padding-right: 25px;
  margin-bottom: 8px;
  background: #51789f;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* transition: background 0.3s, transform 0.2s; */
}

.track-item:hover {
  background: #436782;
  transform: scale(1.02);
}

.track-item.selected {
  background: #394f6b;
  font-weight: bold;
}

/* Progress Bar Container */
.progress-container {
  font-family: Arial, sans-serif;
  margin-top: 20px;
  text-align: center;
  color: #333;
  width: 830px;
}

.progress-container-track {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-left: 0px;
  margin-top: 20px;
  color: #ccc;
}

/* Track progress bar */
.progress-bar-track {
  flex-grow: 1;
  margin: 0 10px;
  -webkit-appearance: none;
  appearance: none;
  height: 4px;
  background-color: #555;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
}

.progress-bar-track::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #68352d;
  border-radius: 50%;
  cursor: pointer;
}

.progress-bar-track::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background-color: #1db954;
  border-radius: 50%;
  cursor: pointer;
}

/* Progress Bar Background */
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 20px;
  height: 15px;
  margin: 10px auto;
  overflow: hidden;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
}

/* Progress Fill */
.progress {
  height: 100%;
  /* background-color: #792d27; */
  background-color: #4caf50;
  width: 0%; /* Dynamically updated */
  border-radius: 20px;
  transition: width 0.3s ease-in-out;
}

.Header-Label {
  font-size: 18px;
  margin-right: 30px;
  opacity: 0.9;
  cursor: pointer;
  color: black;
}

.Header-Label:hover {
  opacity: 0.5;
}

.Header-Selected {
  font-weight: bold;
}

.Header-Image {
  position: absolute;
  top: 200px;
  left: 0;
  bottom: 0;
  right: 0;
  box-sizing: border-box;
  padding: 0;
  border: none;
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
}

.Header-Container-0 {
  width: 100vw;
  height: calc(100vh - 0.8rem);
  max-height: 800px;
}

.Header-Container-1 {
  width: 100%;
  height: 100%;
}
.Header-Container-2 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.Header-Container-3 {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: initial;
  height: initial;
  background: none;
  opacity: 1;
  border: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.splash-button {
  width: 300px;
  height: 50px;
  display: flex;
  justify-content: center;
  border: 3px solid #832623;
  border-radius: 5px;
  cursor: pointer;
}

.splash-login-button {
  height: 35px;
  border: 3px solid #832623;
  background-color: white;
  width: 130px;
  border-radius: 12px;
  transition: 0.5s;
  margin-right: 50px;
  font-size: 16px;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.splash-login-button-small {
  border: 3px solid #832623;
  background-color: white;
  width: 130px;
  height: 35px;
  margin-top: 25px;
  border-radius: 12px;
  transition: 0.5s;
  font-size: 16px;
  text-decoration: none;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.nav-item-logout-small {
  display: none;
}

.splash-button:hover {
  opacity: 0.3;
}

.arrow {
  text-align: center;
  margin: 8% 0;
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#contact h1 {
  display: flex;
  align-items: flex-start;
  gap: 1rem;
}
#contact h1 form {
  display: flex;
  align-items: center;
  margin-top: 0.25rem;
}
#contact h1 form button {
  box-shadow: none;
  font-size: 1.5rem;
  font-weight: 400;
  padding: 0;
}
#contact h1 form button[value="true"] {
  color: #a4a4a4;
}
#contact h1 form button[value="true"]:hover,
#contact h1 form button[value="false"] {
  color: #eeb004;
}

form[action$="destroy"] button {
  color: #f44250;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

#root {
  height: 100%;
  width: 100%;
}

#sidebar {
  width: 24rem;
  background-color: #f7f7f7;
  border-right: solid 1px #e3e3e3;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  transition: 1.2s;
  z-index: 20000;
}

#video-container {
  transition: 1.2s;
  transition: 1s ease-in;
  width: 1162px;
  min-width: 800px;
  margin-top: 130px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

#video-container.closed {
  margin-left: 24rem !important;
  margin-left: 0;
}

.video-container, .video-container-goldenFiddle {
  margin-top: 20px;
  margin-bottom: 20px;
  aspect-ratio: 16 / 9;
  max-width: "100%";
  flex: 3;
  position: relative;
}

  .video-container iframe, .video-container-goldenFiddle iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#sidebar.closed {
  margin-left: -24rem;
}

#sidebar > * {
  padding-left: 2rem;
  padding-right: 2rem;
}

#sidebar img {
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 1rem 2rem;
  border-top: 1px solid #e3e3e3;
  order: 1;
  line-height: 1;
}

#sidebar h1::before {
  margin-right: 0.5rem;
  position: relative;
  top: 1px;
}

#sidebar > div {
  align-items: center;
  gap: 0.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e3e3e3;
}

#sidebar > div form {
  position: relative;
}

#sidebar > div form input[type="search"] {
  width: 16rem;
  padding-left: 2rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='%23999' stroke-width='2'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 0.625rem 0.75rem;
  background-size: 1rem;
  position: relative;
  height: 45px;
  border-radius: 8px;
}

#sidebar > div form input[type="search"].loading {
  background-image: none;
}

#search-spinner {
  width: 1rem;
  height: 1rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%23000' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M20 4v5h-.582m0 0a8.001 8.001 0 00-15.356 2m15.356-2H15M4 20v-5h.581m0 0a8.003 8.003 0 0015.357-2M4.581 15H9' /%3E%3C/svg%3E");
  animation: spin 1s infinite linear;
  position: absolute;
  left: 0.625rem;
  top: 0.75rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#sidebar nav {
  flex: 1;
  overflow: auto;
  padding-top: 1rem;
}

#sidebar nav a span {
  float: right;
  color: #eeb004;
}
#sidebar nav a.active span {
  color: inherit;
}

i {
  color: #818181;
}
#sidebar nav .active i {
  color: inherit;
}

#sidebar ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#sidebar li {
  margin: 0.25rem 0;
}


#sidebar nav a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: pre;
  padding: 0.5rem;
  border-radius: 8px;
  color: inherit;
  text-decoration: none;
  gap: 1rem;
}

#sidebar nav a:hover {
  background: #e3e3e3;
}

#sidebar nav a.active {
  background: hsl(224, 98%, 58%);
  color: white;
}

#sidebar nav a.pending {
  color: hsl(224, 98%, 58%);
}

#detail {
  flex: 1;
  padding: 2rem 4rem;
  width: 100%;
}

#detail.loading {
  opacity: 0.25;
  transition: opacity 200ms;
  transition-delay: 200ms;
}

#contact {
  max-width: 40rem;
  display: flex;
}

#contact h1 {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
  line-height: 1.2;
}

#contact h1 + p {
  margin: 0;
}

#contact h1 + p + p {
  white-space: break-spaces;
}

#contact h1:focus {
  outline: none;
  color: hsl(224, 98%, 58%);
}

#contact a[href*="twitter"] {
  display: flex;
  font-size: 1.5rem;
  color: #3992ff;
  text-decoration: none;
}
#contact a[href*="twitter"]:hover {
  text-decoration: underline;
}

#contact img {
  width: 12rem;
  height: 12rem;
  background: #c8c8c8;
  margin-right: 2rem;
  border-radius: 1.5rem;
  object-fit: cover;
}

#contact h1 ~ div {
  display: flex;
  gap: 0.5rem;
  margin: 1rem 0;
}

#contact-form {
  display: flex;
  max-width: 40rem;
  flex-direction: column;
  gap: 1rem;
}
#contact-form > p:first-child {
  margin: 0;
  padding: 0;
}
#contact-form > p:first-child > :nth-child(2) {
  margin-right: 1rem;
}
#contact-form > p:first-child,
#contact-form label {
  display: flex;
}
#contact-form p:first-child span,
#contact-form label span {
  width: 8rem;
}
#contact-form p:first-child input,
#contact-form label input,
#contact-form label textarea {
  flex-grow: 2;
}

#contact-form-avatar {
  margin-right: 2rem;
}

#contact-form-avatar img {
  width: 12rem;
  height: 12rem;
  background: hsla(0, 0%, 0%, 0.2);
  border-radius: 1rem;
}

#contact-form-avatar input {
  box-sizing: border-box;
  width: 100%;
}

#contact-form p:last-child {
  display: flex;
  gap: 0.5rem;
  margin: 0 0 0 8rem;
}

#contact-form p:last-child button[type="button"] {
  color: inherit;
}

#zero-state {
  margin: 2rem auto;
  text-align: center;
  color: #818181;
}

#zero-state a {
  color: inherit;
}

#zero-state a:hover {
  color: #121212;
}

#zero-state:before {
  display: block;
  margin-bottom: 0.5rem;
  content: url("data:image/svg+xml,%3Csvg width='50' height='33' viewBox='0 0 50 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M38.8262 11.1744C37.3975 10.7252 36.6597 10.8766 35.1611 10.7128C32.8444 10.4602 31.9215 9.55475 31.5299 7.22456C31.3108 5.92377 31.5695 4.01923 31.0102 2.8401C29.9404 0.591789 27.4373 -0.416556 24.9225 0.158973C22.7992 0.645599 21.0326 2.76757 20.9347 4.94569C20.8228 7.43263 22.2399 9.5546 24.6731 10.2869C25.8291 10.6355 27.0574 10.8109 28.2646 10.8998C30.4788 11.0613 30.6489 12.292 31.2479 13.3051C31.6255 13.9438 31.9914 14.5731 31.9914 16.4775C31.9914 18.3819 31.6231 19.0112 31.2479 19.6499C30.6489 20.6606 29.9101 21.3227 27.696 21.4865C26.4887 21.5754 25.2581 21.7508 24.1044 22.0994C21.6712 22.834 20.2542 24.9537 20.366 27.4406C20.4639 29.6187 22.2306 31.7407 24.3538 32.2273C26.8686 32.8052 29.3717 31.7945 30.4415 29.5462C31.0032 28.3671 31.3108 27.0312 31.5299 25.7304C31.9238 23.4002 32.8467 22.4948 35.1611 22.2421C36.6597 22.0784 38.2107 22.2421 39.615 21.4443C41.099 20.36 42.4248 18.7328 42.4248 16.4775C42.4248 14.2222 40.9961 11.8575 38.8262 11.1744Z' fill='%23E3E3E3'/%3E%3Cpath d='M15.1991 21.6854C12.2523 21.6854 9.84863 19.303 9.84863 16.3823C9.84863 13.4615 12.2523 11.0791 15.1991 11.0791C18.1459 11.0791 20.5497 13.4615 20.5497 16.3823C20.5497 19.3006 18.1436 21.6854 15.1991 21.6854Z' fill='%23E3E3E3'/%3E%3Cpath d='M5.28442 32.3871C2.36841 32.38 -0.00698992 29.9882 1.54551e-05 27.0652C0.00705187 24.1469 2.39884 21.7715 5.32187 21.7808C8.24022 21.7878 10.6156 24.1796 10.6063 27.1027C10.5992 30.0187 8.20746 32.3941 5.28442 32.3871Z' fill='%23E3E3E3'/%3E%3Cpath d='M44.736 32.387C41.8107 32.4033 39.4096 30.0373 39.3932 27.1237C39.3769 24.1984 41.7428 21.7973 44.6564 21.7808C47.5817 21.7645 49.9828 24.1305 49.9993 27.0441C50.0156 29.9671 47.6496 32.3705 44.736 32.387Z' fill='%23E3E3E3'/%3E%3C/svg%3E%0A");
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.close-toggle:hover {
  opacity: 0.4;
}

#waveform {
  width: 100%;
}

#blur {
  filter: blur(3px);
}

.Button:hover {
  opacity: 0.7 !important;
}

.ButtonGrow {
  transition: 0.3s;
}
.ButtonGrow:hover {
  transform: scale(1.05);
}

.ButtonFull:hover {
  opacity: 1 !important;
}

.StoreButton {
  box-shadow: -1px 2px #fff, 0 1px 1px #666, inset 0 -1px 1px rgba(0, 0, 0, 0.5),
    inset 0 -1px 1px rgba(255, 255, 255, 0.8);
  border: solid #ccc 0px;
}

.StoreBtn {
  width: 175px;
  align-items: center;
  display: flex;
  flex-direction: column;
  border: 1px solid grey;
  margin-top: 10px;
  margin-right: 20px;
  border-radius: 4px;
  background-color: white;
}

.BtnHeight1 {
  height: 235px;
}

.BtnHeight2 {
  height: 290px;
}

.BtnHeight3 {
  height: 305px;
}

.BtnHeight4 {
  height: 205px;
}

.track-list-item:hover {
  background-color: #e5e5e5;
  cursor: pointer;
}

.radio-station-title {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
  margin-top: 35px;
}

.track-list-item {
  transition: all 0.3s ease;
  font-size: 1em;
  margin: 2px 0;
  padding-left: 40px;
  border-radius: 4px;
}

/* Style for selected track */
.track-list-item.selected {
  background-color: #e0e0e0;
  font-weight: bold;
  color: #333;
  border-left: 4px solid #792d27; /* Spotify-like green accent */
  position: relative;
}

/* Add play icon for the selected track */
.track-list-item.selected::before {
  content: "▶"; /* Play icon */
  font-size: 1.2em;
  color: #792d27; /* Match accent color */
  margin-right: 10px;
  position: absolute;
  left: 10px;
}

/* Adjust padding when selected for alignment */
.track-list-item.selected .track-title {
  margin-left: 20px; /* Offset to align with the play icon */
}

.track-list-item:hover::before {
  content: "▶";
  margin-right: 10px;
  color: black; /* Spotify's green color */
}

/* dashboard */
/* General Layout */
.dashboard-container {
  width: 100vw;
  height: 100vh;
  background-color: #f8f6f1;
  display: flex;
  flex-direction: column;
  /* font-family: Arial, sans-serif; */
  padding: 20px;
}

/* Header */
.dashboard-header {
  width: 100%;
  height: 65px;
  background-color: #f0ebe0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
}

.header-left .welcome-message {
  font-weight: bold;
  font-size: 22px;
}

.header-nav .nav-link {
  margin: 0 15px;
  cursor: pointer;
  font-weight: bold;
  transition: color 0.3s ease;
}

.header-nav .nav-link:hover {
  color: #73322b;
}

/* Main Content */
.dashboard-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Progress Section */
.progress-section {
  background-color: #ffffff; /* Softer color */
  color: white;
  padding: 20px;
  border-radius: 12px; /* Rounder corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 85%;
  min-width: 900px;
  margin-top: 35px;
  animation: fadeIn 1s ease;
}

.reminder-section {
  background-color: #5a779c; /* Softer color */
  color: white;
  padding: 20px;
  border-radius: 12px; /* Rounder corners */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 85%;
  min-width: 900px;
  margin-bottom: 20px;
  margin-top: 25px;
  animation: fadeIn 1s ease;
}

.progress-bar-profile {
  width: 100%;
  background-color: #efebe1;
  height: 15px;
  border-radius: 6px;
  margin-top: 8px;
  overflow: hidden;
}

.progress-bar-fill-profile {
  background-color: #517a9f;
  opacity: 0.6;
  height: 100%;
  transition: width 0.5s ease;
}

.progress-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.stat-card {
  background-color: #f9f9f9;
  color: black;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  width: 30%;
  height: 220px;
  display: flex;
  justify-content: "center";
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.divider {
  height: 2px;
  background-color: white;
  margin: 20px 0;
}

/* Action Cards */
.action-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 100%;
}

.nav-links {
  display: none; /* Initially hidden */
  background-color: #51789f; /* Match navbar color */
  width: 100%;
  padding: 25px 0px;
  transition: 1s;
}

.nav-links-visible {
  background-color: #51789f; /* Match navbar color */
  width: 100%;
  padding: 25px 0px;
  transition: 1s;
}

.nav-links-visible-home {
  padding: 8px 0px;
}

.nav-links-visible-home-container {
  display: none;
  position: absolute;
  top: 100px;
  width: 100%;
}

.action-card {
  width: 300px;
  height: 350px;
  background-color: #eaf2fa; /* Softer color */

  color: #3a5a8d;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px; /* Rounder corners */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  animation: fadeIn 1s ease;
}

.action-card:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.action-card-image,
.action-card-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.reminder-btn {
  padding: 10px 20px;
  color: #73322b;
  background-color: white;
  border: none;
  border-radius: 4px;
  margin: 0 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.reminder-btn:hover {
  background-color: #f0ebe0;
}

/* Base styling for each station item */
.station-item {
  margin: 10px 0px;
  padding: 12px 25px;
  font-size: 16px;
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;
  border-radius: 8px; /* Rounded corners for a modern look */
}

/* Hover effect for unselected stations */
.station-item:hover {
  background-color: #51789f; /* Spotify-like green accent */
  opacity: 0.5;
  color: white;
}

/* Styling for selected station */
.station-item.selected {
  background-color: #51789f; /* Spotify-like green accent */
  color: white;
  font-weight: bold;
  border-radius: 8px; /* Ensure it matches the hover rounded corners */
}

/* Optional: Add an indicator next to the selected station */
.station-item.selected::before {
  content: "";
  width: 6px;
  height: 100%;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Control bar container */
.control-bar-play-along {
  width: 100%;
  background-color: #efebe1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #e0e0e0;
  z-index: 1;
  height: 130px;
  position: relative;
}

.control-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #efebe1;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
  border-top: 1px solid #e0e0e0;
  z-index: 1000;
  height: 90px;
}

.control-bar-wave {
  position: absolute;
  right: 100px;
  top: -25px;
}

.control-bar-track-title {
  position: absolute;
  left: 6vw;
  min-width: 300px;
  text-align: left;
}

/* Button styling */
.control-btn {
  background: none;
  border: none;
  font-size: 20px;
  color: #333;
  margin: 0 15px;
  cursor: pointer;
  transition: color 0.3s ease, transform 0.2s ease;
}

.control-btn:hover {
  color: #792d27; /* Spotify-like green color */
  transform: scale(1.1);
}

.play-pause {
  font-size: 24px; /* Larger button for play/pause */
  color: #792d27;
}

/* Progress container for track timing and progress bar */
.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  color: #ccc;
}

.progress-label {
  font-size: 15px; /* Slightly larger font */
  font-weight: bold; /* Emphasize text */
  color: #444; /* Neutral text color */
  margin-bottom: 5px; /* Space between text and bar */
  width: 100%;
  text-align: left;
}

.current-time,
.total-time {
  font-size: 12px;
  width: 35px;
  text-align: center;
}

/* Track progress bar */
/* .progress-bar {
  flex-grow: 1;
  margin: 0 10px;
  -webkit-appearance: none;
  appearance: none;
  height: 6px;
  background-color: #555;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}

.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background-color: #792d27;
  border-radius: 50%;
  cursor: pointer;
}

.progress-bar::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background-color: #792d27;
  border-radius: 50%;
  cursor: pointer;
} */

/* Sidebar styling */
.sidebarRadio {
  width: 120px;
  height: 90vh;
  position: fixed;
  top: 80px;
  left: station-item0px;
  background-color: #eeebe2;
  color: white;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: black;
  margin-top: 45px;
}

.station-item-radio {
  padding: 10px 20px;
  font-size: 14px;
  height: 60px;
  display: flex;
  width: "100%";
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #c0c0c0;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  border-top: 1px solid white;
  transition: all 0.3 ease;
}

.station-item-radio:hover {
  background-color: #fff;
  background-color: #5a779c;
  color: #fff;
}

.station-item-radio.selected {
  background-color: #5a779c; /* Spotify-like green */
  color: white;
  font-weight: bold;
}

.course-btn {
  box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.1);
}

body,
.main-container {
  height: 100vh;
  overflow: hidden;
  margin: 0; /* Removes default body margin */
}

.nav-item {
  transition: transform 0.3s ease, opacity 0.3s ease;
  margin-right: 65px;
  cursor: pointer;
  color: white;
  opacity: 0.8;
  width: "100%";
  padding: 5px 0px; /* Adjust padding as needed */
}

.nav-item::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px; /* Adjust the thickness of the underline */
  background-color: currentColor; /* Matches the text color */
  transition: width 0.3s ease; /* Smooth animation for the underline */
}

.nav-item:hover {
  opacity: 1;
  transform: scale(1.05);
  /* color: #007bff; Adjust hover text color if desired */
}

.nav-item:hover::after {
  width: 100%; /* Expands underline on hover */
}

.nav-item-selected {
  margin-right: 65px;
  cursor: pointer;
  font-weight: bold;
  color: white;
  padding: 5px 0px; /* Adjust padding as needed */
  border-bottom: 3px solid #fff;
}

.nav-item.select::after {
  content: ""; /* Necessary for the pseudo-element */
  position: absolute;
  bottom: -4px; /* Adjust spacing between text and underline */
  left: 0;
  width: 100%; /* Full underline */
  height: 4px; /* Thickness of the underline */
  background-color: currentColor; /* Matches text color */
  width: 100%;
}

.navbar {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2), 0px 2px 4px rgba(0, 0, 0, 0.06);
  width: 100%;
  background-color: #51789f;
  height: 105px;
  position: absolute;
  top: 0;
  overflow: hidden;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  z-index: 10000;
}

.navbar-home-logout {
  display: flex;
  align-items: center;
  height: 35px;
}

.navbar-image {
  width: 135px;
  object-fit: cover;
}

.fade-in {
  animation: fadeIn 1s ease;
  width: 100% !important;
}

/* Fade-In Animation */
.fade-content {
  animation: fadeIn 0.5s ease-in-out;
}

/* Modal Background */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 300000;
}

/* Modal Container */
.modal-container {
  background: #ffffff;
  width: 90%;
  max-width: 500px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  animation: fadeInModal 0.25s ease-in-out; /* Apply fade-in effect */
}

/* Title */
.modal-title {
  font-size: 32px;
  color: #4a5e8d;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Message */
.modal-message {
  font-size: 16px;
  padding: 10px 4px;
  color: #333333;
  margin-bottom: 20px;
}

/* Button */
.modal-button {
  background-color: #5a779c;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: opacity 0.3s ease;
  margin-left: 4px;
  margin-right: 4px;
}

.modal-button-close {
  background-color: #c0c0c0;
}

.modal-button:hover {
  opacity: 0.8;
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: scale(0.9); /* Slight zoom-in effect */
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Scroll-to-Top Button */
.scroll-to-top {
  position: fixed;
  bottom: 50px;
  left: 50px;
  width: 50px;
  height: 50px;
  background-color: #3a5a8d; /* Customize color */
  color: white;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
}

.scroll-to-top.show {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top:hover {
  background-color: #2b4570; /* Darker shade on hover */
}

/* Icon styling */
.scroll-to-top-icon {
  font-size: 20px;
}

.countdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  color: #fff;
  font-size: 5rem;
  font-weight: bold;
  transition: opacity 0.5s ease-out;
}

.countdown-overlay.fade-out {
  opacity: 0;
  pointer-events: none; /* Prevent interaction during fade-out */
}

.cousre-header {
  flex-direction: row;
}

.flex-switch {
  align-items: flex-start;
  flex-direction: row;
}

.course-video {
  width: 870px;
  height: 470px;
}
.nav-right {
  margin-right: 110px;
}

.bottom-display {
  display: none;
}

/* Section Title Styling */
.section-title {
  font-size: 1.3em;
  color: #444;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

/* List and Item Styling */
.section-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.section-list li {
  margin-bottom: 15px;
}

.section-list li a.link {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
}

.section-list li a.link:hover {
  text-decoration: underline;
}

/* Link Divider */
.link-divider {
  color: #666;
  margin: 0 5px;
}

/* Go Back Button */
.go-back-button {
  display: inline-block;
  margin-top: 20px;
  padding: 8px 15px;
  background-color: #ddd;
  border: none;
  border-radius: 5px;
  font-size: 0.9em;
  cursor: pointer;
  color: #555;
  transition: background-color 0.2s ease;
  text-align: center;
}

.go-back-button:hover {
  background-color: #bbb;
}

.navImage {
  margin-left: 50px;
  display: flex;
  align-items: center;
  flex: 1;
}
.nav-item-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-item-container-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-item-container-1-small {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8vw;
}

.nav-item-container-2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-item-container-2-small {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8vw;
  margin-top: 25px;
}

.flex-switch-container {
  height: 65vh;
  width: 72%;
}

.video-container, .video-container-goldenFiddle {
  width: 830px;
  justify-content: flex-start;
  display: flex;
}

.nav-image {
  margin-left: 85px;
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.radio-track {
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #efebe1;
  width: 50vw;
  max-width: 715px;
}

.radio-header {
  width: 50vw;
  padding-top: 25px;
  max-width: 715px;
}

.radio-tracks-container {
  height: 56vh;
  overflow: scroll;
  margin-top: 30px;
  width: 100vw;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

@media (max-width: 1130px) {
  .splash-login-button {
    margin-right: 20px;
  }
  .nav-item {
    margin-right: 50px !important;
    font-size: 14px;
  }
  .nav-item-selected {
    margin-right: 50px !important;
  }
  .navImage {
    margin-left: 20px;
  }
  .nav-right {
    margin-right: 50px;
  }
  .control-bar-track-title,
  .control-bar-wave {
    display: none;
  }
  .speed-control-container {
    left: 10px;
  }
}

.hamburger {
  display: none;
  font-size: 50px;
  cursor: pointer;
  color: white;
  margin-right: 40px;
}

@media (max-width: 767px) {
  .nav-item-logout-small {
    display: flex;
  }
  .nav-links-visible-home-container {
    display: flex;
  }
  .nav-links.active {
    display: flex; /* Shows menu when active */
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .splash-login-button {
    display: none;
  }
  .navImage {
    flex: 1 !important;
  }
  .hamburger {
    display: block;
  }
  .nav-item-container-splash {
    display: none;
  }
  .navbar {
    height: 100px;
  }
  .course-header {
    margin-top: 30px;
  }
  .sidebarRadio {
    display: none;
    top: 18vw;
  }
  .radio-track,
  .radio-header {
    width: 75vw;
  }

  .radio-header {
    padding-top: 8vw;
  }
  .radio-header-small {
    width: 100vw;
    justify-content: center;
    display: flex !important;
  }
  .radio-tracks-container {
    height: 50vh;
  }
}

@media (max-width: 975px) {
  .Video-title {
    font-size: 24px !important;
  }

  #video-container {
    width: 100%;
    min-width: unset;
    transition: 0s;
  }

  .nav-item-container {
    flex-direction: column;
    flex: 1;
  }
  .nav-item-container-2 {
    margin-top: 12px;
  }
  .navImage {
    flex: 0;
  }
  .nav-item {
    margin-right: 20px !important;
    margin-left: 20px !important;
    font-size: 12px;
  }
  .nav-item-selected {
    margin-right: 20px !important;
    margin-left: 20px !important;
    font-size: 14px;
  }
  .nav-right {
    margin-right: 0px;
  }
  .nav-image {
    margin-left: 40px;
  }
}

html body.vp-center {
  height: auto !important;
  display: block !important;
  overflow: visible !important; /* Adjust based on your layout needs */
}

.progress-container {
  margin-top: 10px !important;
}

.track-title {
  color: black;
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: bold;
  font-size: 32px;
}
@media (max-width: 1220px) {
  /* .Video-title {
    font-size: 32px;
  } */
  .playback-container {
    width: 100%;
  }

  .course-btn {
    width: 90vw !important;
  }
  .flex-switch-container,
  .video-container,
  .progress-container, .video-container-goldenFiddle {
    width: 78vw;
  }

  .video-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start !important;
  }
  .video-container-goldenFiddle {
    display: flex;
    justify-content: flex-start;
    align-items: center !important;
  }
  .course-align {
    align-items: center;
  }
  .course-header {
    flex-direction: column;
    gap: 10px;
  }
  .flex-switch {
    align-items: center;
    /* flex-direction: column; */
  }
  .course-complete {
    margin-top: 20px;
  }
  .course-sidebar {
    display: none !important;
  }
  .courseModalBtn {
    display: flex !important;
  }
  .course-video {
    width: 100%;
    height: 90%;
  }
  body,
  html,
  .player,
  .fallback {
    height: unset !important;
  }

  body.vp-center {
    height: unset !important;
  }

  body.vp-center {
    height: unset !important;
    display: block !important; /* Adjust flex behavior if needed */
  }

  .vp-center {
    height: 0 !important;
    align-items: flex-start !important;
  }
  .course-display {
    display: none;
  }

  .nav-right {
    margin-right: 50px;
  }
}

.sidebarToggle {
  margin-left: 85px;
  display: flex;
  align-items: center;
  flex: 1;
}

@media (max-width: 900px) {
  .bottom-display {
    display: flex;
  }

  .course-video {
    width: 100%;
    min-height: 300px;
    height: 60%;
    margin-bottom: 100px;
  }
  .sidebarToggle {
    margin-left: 50px;
  }
}

@media (max-width: 600px) {
  /* .navImage {
    display: none;
  } */
   .sidebar {
    overflow-x: scroll;
   }

   .course-header {
    margin-top: 40px;
    gap: 10px;
  }

  .course-video {
    width: 100%;
    min-height: 200px;
    height: 45%;
    margin-bottom: 100px;
  }
  .speed-control-container {
    position: relative !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    z-index: 10000;
  }
  .speed-control-container-play {
    position: relative !important;
    top: 0px !important;
    left: 0px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    z-index: 10000;
  }
  .countdown-play {
    position: relative !important;
    right: 0px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    z-index: 10000;
  }
  .control-bar-play-along {
    flex-direction: column;
    height: auto;
    gap: 25px;
    padding: 10px 0;
  }
  .video-container-goldenFiddle{
    height: 100% !important;
  }
}

@media (max-width: 500px) {
  .course-header {
    margin-top: 50px;
    gap: 10px;
  }
  .control-bar {
    left: auto;
  padding: 10px 5px;
  }
}

.countdown-play {
  background-color: #70322a;
  color: white;
  height: 50px;
  width: 100px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  transition: 0.5s;
  position: absolute;
  right: 25px;
}

/* Speed Control Styling */
.speed-control-container {
  position: absolute;
  right: 80px;
  top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  z-index: 10000;
}

.speed-control-container-play {
  position: absolute;
  left: 25px;
  top: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  z-index: 10000;
}

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-label {
  font-size: 12px;
  margin-bottom: 8px;
  font-weight: bold;
}

.vertical-slider {
  margin-top: 5px;
  width: 90px;
  accent-color: #7b2d28; /* Change slider thumb and track color */
}

.speed-input {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.speed-input input {
  border: 1px solid #ccc;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  transition: border 0.3s ease;
}

.speed-input input:focus {
  border: 1px solid #7b2d28;
}

/* Optional hover effect for better UI */
.speed-input input:hover {
  border: 1px solid #c0c0c0;
}

.nav-item-small {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* Hide scrollbar for the specific element */
.scrollbar-none {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scrollbar-none::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}
